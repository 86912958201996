<template>
    <Header></Header>
    <div class="container">
        <div class="page_title m1620">
            <div class="fs15">SIGNEO COLOR</div>
            <div class="fs64 fwb">诗珑色彩</div>
        </div>
        <div class="flex-box navigator_box m1620">
            <router-link :to="{ name: 'trends' }" class="item active">色彩趋势</router-link>
            <router-link :to="{ name: 'card' }" class="item">色卡查询</router-link>
            <router-link :to="{ name: 'match' }" class="item">色系搭配</router-link>
            <router-link :to="{ name: 'guide' }" class="item">风格指南</router-link>
        </div>
        <swiper class="trend_box" @swiper="onSwiper" :slides-per-view="preview" :pagination="false" :modules="modules">
            <swiper-slide v-for="(item, index) in colorTrends" :key="index">
                <a :href="item.url" target="_blank">
                    <img :src="item.image" class="icon_trend" alt="">
                </a>
            </swiper-slide>
        </swiper>
    </div>
    <Footer></Footer>
</template>
<script setup>
import { ref, reactive, onMounted, onBeforeUnmount, inject } from "vue";
const api = inject('api'); // 使用inject来注入接口
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
const route = useRoute();
//设置meta和标题
import { useHead } from "@unhead/vue";
useHead({
    title: "色彩趋势_诗珑",
    meta: [
        {
            name: "description",
            content: "诗珑",
        },
        {
            name: "keywords",
            content: "诗珑",
        },
    ],
});
// 引入swiper
import { A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
const modules = [A11y, Autoplay];
const mySwiper = ref(null);
const onSwiper = (swiper) => {
    mySwiper.value = swiper; // 保存 Swiper 实例
};
// 设置一页显示几个轮播
let preview = ref(3.5);
// 监听触控板事件
const handleTouchPadScroll = (event) => {
    if (event.deltaX > 0) {
        mySwiper.value.slideNext();
    } else {
        mySwiper.value.slidePrev();
    }
};

// 接收传递过来的下标
onMounted(() => {
    // 如果是h5则设置轮播一屏1.5个
    if (document.documentElement.clientWidth < 980) {
        preview.value = 1.5;
    }
    colorTrend();
    window.addEventListener('wheel', handleTouchPadScroll, { passive: false });
})
onBeforeUnmount(() => {
    window.removeEventListener('wheel', handleTouchPadScroll);
});
// 修改下标
onBeforeRouteUpdate((to) => {
    colorTrend();
});


// 色彩趋势
let colorTrends = ref([]);
const colorTrend = async () => {
    try {
        const res = await api.getColorTrend(); // 调用接口
        colorTrends.value = res.data.data;
    } catch (error) {
        console.error(error);
    }
}
</script>


<style lang="less" scoped>
.trend_box {
    padding-top: 120px;
    margin-left: calc((100vw - 1620px) / 2);

    .icon_trend {
        width: 100%;
        height: auto;
        display: block;
        max-width: 500px;
    }
}

@media only screen and (max-width: 980px) {
    .trend_box {
        padding: 0;
        width: 100vw;
        margin-left: 0;
        .icon_trend:first-child{margin-left: 15px;}
    }
}
</style>